
<template>
<el-form  label-position="top" ref="form" :model="form" style=" margin:20px;  padding-top:50px; max-width:300px;">
  <el-form-item label="Фраза/слово">
    <el-input v-model="form.phrase"></el-input>
  </el-form-item>
  <el-form-item label="Суд">
     <el-select
            v-model="form.courts"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="выберите">
            <el-option
            v-for="(item,key) in courts"
            :key="key"
            :label="item"
            :value="key">
            </el-option>
        </el-select>
  </el-form-item>
<!-- 
   <el-form-item label="Вид спора">
     <el-select
            v-model="form.types"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="выберите">
           <el-option
            v-for="(item,key) in types"
            :key="key"
            :label="item"
            :value="key">
            </el-option>
        </el-select>
  </el-form-item>

   <el-form-item label="Категория спора">
     <el-select
            v-model="form.categories"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="выберите">
             <el-option
            v-for="(item,key) in categories"
            :key="key"
            :label="item"
            :value="key">
            </el-option>
        </el-select>
  </el-form-item> -->


  <!-- <el-form-item label="Стоп-слова">
    <el-input type="textarea" placeholder='суд, антракт' v-model="form.stops"></el-input>
  </el-form-item> -->
  <p class='mb-3' style="padding-bottom:10px"> В платной версии больше фильтров:  
    возможноcть мониторинга <b>по "неточным" фразам</b> с учетом синонимов, искать акты в <b>судах общей юрисдикции</b>, выгружать в Excel - <a target='_blank' href='https://kazna.tech/tarif-word'>подробнее</a></p>

  <el-form-item>
    <el-button style="background-color:black; color:white;" @click="add_sub()">Создать</el-button>
    <el-button @click="back()">Отмена</el-button>
  </el-form-item>

</el-form>
</template>

<script>
import firebase from 'firebase';
  export default {
    data() {
      return {
        courts:[],
        types:[],
        categories:[],
        value: [],
        user: null,
        form: {
          phrase: '',
          courts:[],
          categories:[],
          types:[],
          stops: ''
        }
      }
    },
    created() {
        firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            this.user = user;
            this.getCourt()
            this.getCats()
            this.getTypes()
        } else {
            this.user = null;
            this.$router.push('/login');
        }
        });
    },
    methods: {
        // GET_COURT
    add_sub(){
        if (this.form.phrase.length<3){
            alert('введите поисковую фразу для монитроинга, более 3х символов')
            return
        }
        var data = this.form;
        data['uid'] = this.user.uid;
        this.$store.dispatch('ADD_SUB',data)
        .then(res => {
             this.$store.dispatch('GET_USER', this.user)
            this.$router.push('/home');
        })
        .catch(e => {
            alert('ошибка в программе, напишите в чат в правом нижнем углу')
        })
    },
    getCats(){
          this.$store.dispatch('GET_CATEGORIES')
        .then(res => {
            this.categories = res.items
        })
        .catch(e => {
            this.snackbarError = true
        })
      },

      getCourt(){
          this.$store.dispatch('GET_COURT')
        .then(res => {
            this.courts = res.items
        })
        .catch(e => {
            this.snackbarError = true
        })
      },


      getTypes(){
          this.$store.dispatch('GET_TYPES')
        .then(res => {
            this.types = res.items
        })
        .catch(e => {
            this.snackbarError = true
        })
      },

      back(){
        this.$router.push('/home');
      },
      onSubmit() {
        console.log('submit!');
      }
    }
  }
</script>

<style>
.no-select{
  -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;
}
.mb-3{
  margin-bottom:10px;
}
</style>

